/*=========================================================================================
  File Name: moduleExpertMutations.js
  Description: Expert Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Experts.unshift(item)
  },
  SET_Expert(state, Experts) {
    state.Experts = Experts
  },
  UPDATE_Expert(state, Expert) {
    const ExpertIndex = state.Experts.findIndex((p) => p.ID == Expert.ID)
    Object.assign(state.Experts[ExpertIndex], Expert)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Experts.findIndex((p) => p.ID == itemId)
    state.Experts.splice(ItemIndex, 1)
},
}
