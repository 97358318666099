/*=========================================================================================
  File Name: moduleExpertActions.js
  Description: Expert Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddExpert({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Expert/AddExpert", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllExperts({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Expert/GetAll")
        .then((response) => {
          commit('SET_Expert', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  SearchExpert({ commit },search) {
    return new Promise((resolve, reject) => {
      axios.post("api/Expert/SearchExpert",search)
        .then((response) => {
          commit('SET_Expert', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateExpert(context, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Expert/UpdateExpert", item)
        .then((response) => {
          // commit('UPDATE_Expert', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetExpert(context, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Expert/GetExpert?ID="+ itemid)
        .then((response) => {
          debugger
          // commit('UPDATE_Expert', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteExpert({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Expert/DeleteExpert?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
  DeleteExpertComment(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Expert/DeleteExpertComment?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteExpertLike(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Expert/DeleteExpertLike?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteExpertRate(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Expert/DeleteExpertRate?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  DeleteExpertFavorit(context, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Expert/DeleteExpertFavorit?ID="+item.ID)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
